import { GridFilterItem } from '@material-ui/x-grid';
import { FilterIntervalInput } from './filter-interval-input';
import { FilterTextInput } from './filter-text-input';

type ColumnItem = {
    value: any;
};

export const notEquals = {
    label: 'Не равно',
    value: 'ne',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }

        return (columnItem: ColumnItem) => columnItem.value?.toString() !== filterItem.value?.toString();
    },
    InputComponent: FilterTextInput,
};

export const equals = {
    label: 'Равно',
    value: 'eq',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }

        return (columnItem: ColumnItem) => columnItem.value?.toString() === filterItem.value?.toString();
    },
    InputComponent: FilterTextInput,
};

export const contain = {
    label: 'Включает',
    value: 'contain',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }

        return (columnItem: ColumnItem) => columnItem.value?.toString?.().includes?.(filterItem.value);
    },
    InputComponent: FilterTextInput,
};

export const interval = {
    label: 'Интервал',
    value: 'interval',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }
        const from = filterItem.value?.from || -Number.MAX_SAFE_INTEGER;
        const to = filterItem.value?.to || Number.MAX_SAFE_INTEGER;

        return (columnItem: ColumnItem) => {
            const value = typeof columnItem.value === 'number' ? columnItem.value : parseFloat(columnItem.value);
            return value >= from && value <= to;
        };
    },
    InputComponent: FilterIntervalInput,
};

export const greater = {
    label: 'Больше чем',
    value: 'gte',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }

        return (columnItem: ColumnItem) => columnItem.value > filterItem.value;
    },
    InputComponent: FilterTextInput,
};

export const less = {
    label: 'Меньше чем',
    value: 'lte',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }

        return (columnItem: ColumnItem) => columnItem.value < filterItem.value;
    },
    InputComponent: FilterTextInput,
};
