import React from 'react';
import { Menu } from '@material-ui/core';

export type SettingColumnMenuProps = {
    menuAnchor: HTMLElement | null;
    handleClose: () => void;
    open: boolean;
};

export const SettingColumnMenu: React.FC<SettingColumnMenuProps> = React.memo(
    ({ menuAnchor, handleClose, open, children }) => (
        <Menu
            className="x-grid-table-menu"
            open={open}
            anchorEl={menuAnchor}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {children}
        </Menu>
    )
);
