/* eslint-disable */
import React from 'react';
import classnames from "classnames";
import { IconButton } from "@material-ui/core";

import DeleteIcon from '../../../static/svg/delete.svg';
import FileIcon from '../../../static/svg/file.svg';

import {DropzoneItemProps} from './dropzone-types';

import './dropzone.scss';

export const DropzoneItem: React.FC<DropzoneItemProps> = ({
  id,
  name,
  url,
  isRequired,
  onDelete,
  className,
}) => {
  const handleDeleteItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete({ id, name, url })
    }
  }

  return (
    <li key={name} className="dropzone__file-item">
      <a
        className="dropzone__file-link"
        download
        href={url}
        onClick={(e) => e.stopPropagation()}
      >
        <FileIcon />
        <span className={classnames("dropzone__file-text", className)}>
          {name}
        </span>
      </a>
      {!isRequired && (
        <IconButton className="dropzone__delete-item-button" onClick={handleDeleteItem}>
          <DeleteIcon />
        </IconButton>
      )}
    </li>
  )
}