import React from 'react';
import ArrowLeftIconSvg from '../../static/svg/arrow-left.svg';
import FileIconSvg from '../../static/svg/file.svg';
import UploadIconSvg from '../../static/svg/upload-icon.svg';
import PlusIconSvg from '../../static/svg/plus-icon.svg';
import DeleteIconSvg from '../../static/svg/delete.svg';
import LogoIconSvg from '../../static/svg/logo.svg';
import LogoWithTextIconSvg from '../../static/svg/logo-with-text.svg';
import LogoutIconSvg from '../../static/svg/logout.svg';

export const FileIcon: React.FC = () => <FileIconSvg />;

export const ArrowLeftIcon: React.FC = () => <ArrowLeftIconSvg />;

export const UploadIcon: React.FC = () => <UploadIconSvg />;

export const PlusIcon: React.FC = () => <PlusIconSvg />;

export const DeleteIcon: React.FC = () => <DeleteIconSvg />;

export const LogoIcon: React.FC = () => <LogoIconSvg />;

export const LogoWithTextIcon: React.FC = () => <LogoWithTextIconSvg />;

export const LogoutIcon: React.FC = () => <LogoutIconSvg />;
