import React from 'react';
import { GridFilterInputValueProps } from '@material-ui/x-grid';
import { TextField } from '@material-ui/core';

export const FilterTextInput: React.FC<GridFilterInputValueProps> = ({ item, applyValue }) => {
    const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        applyValue({ ...item, value: event.target.value || '' });
    };

    return (
        <TextField
            onChange={handleFilterChange}
            value={item.value || ''}
            label="Значение"
            placeholder="Значение фильтра"
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};
