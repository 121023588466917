/* eslint-disable */
import React from 'react';

import { DropzoneListProps, DropzoneItemProps } from './dropzone-types';

import { DropzoneItem } from './dropzone-item';

export const DropzoneList: React.FC<DropzoneListProps> = ({
  attachments, onDelete, listTitle }) => (
    <div className="dropzone__files">
      {listTitle && (
        <div className="dropzone__files-title">{listTitle}</div>
      )}
      <ul className="dropzone__file-list">
        {attachments.map(({ id, name, url, isRequired }: DropzoneItemProps) => (
          <DropzoneItem
            id={id}
            name={name}
            url={url}
            isRequired={isRequired}
            onDelete={onDelete}
          />
          ))}
      </ul>
    </div>
);
