import React from 'react';
import { Button as MuiButton, ButtonTypeMap } from '@material-ui/core';
import { MenuOpen } from '@material-ui/icons';
import classnames from 'classnames';

import './styles.scss';

export type ButtonProps = ButtonTypeMap<{
    className?: string;
    /**
     * Пример описания пропса для сторибука
     */
    type?: 'primary' | 'primaryCircle' | 'secondary' | 'warning' | 'default';
    htmlType?: 'submit' | 'reset' | 'button';
    title?: React.ReactChild;
    needStartIcon?: boolean;
    onClick?(event?: React.MouseEvent<HTMLElement>): void;
}>['props'];

export const Button: React.FC<ButtonProps> = ({
    className,
    title = 'Ок',
    type = 'primary',
    htmlType,
    onClick,
    needStartIcon,
    ...rest
}) => (
    <MuiButton
        variant="contained"
        className={classnames('button', type, className)}
        onClick={onClick}
        type={htmlType}
        startIcon={needStartIcon && <MenuOpen className="menuIcon" />}
        {...rest}
    >
        {title}
    </MuiButton>
);
