/* eslint-disable */
import React from 'react';
import {useDropzone} from 'react-dropzone';
import classnames from 'classnames';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { Loader } from "../loader";

import { DropzoneProps } from './dropzone-types';
import { DropzoneList } from './dropzone-list';

import './dropzone.scss';

export const Dropzone: React.FC<DropzoneProps> = ({
  attachments,
  className,
  isLoading,
  onDrop,
  onDelete,
  title,
  ...props
}) => {
  const {getRootProps, getInputProps} = useDropzone({ ...props, onDrop });

  return (
    <>
      {title && (
        <div className="dropzone__files-title">{title}</div>
      )}
      <div className={classnames('dropzone', {
        start: attachments.length > 0
      }, className)} {...getRootProps()}>
        <Loader onClick={(e) => e.stopPropagation()} visible={Boolean(isLoading)} />
        <input {...getInputProps()} />

        <DropzoneList attachments={attachments} onDelete={onDelete} />
        <p className="dropzone__caption">
          <AttachFileIcon/> Кликните по полю или перетащите сюда файл
        </p>
      </div>
    </>
  );
}