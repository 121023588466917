import React from 'react';
import classnames from 'classnames';

import './styles.css';

export type LoaderProps = {
    visible: boolean;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const Loader: React.FC<LoaderProps> = ({ visible, className, onClick }) =>
    visible ? (
        <div onClick={onClick} className={classnames('loader-backdrop', className)}>
            <div className="loader" />
        </div>
    ) : null;
