import React from 'react';
import classnames from 'classnames';
import { Drawer as DefaultDrawer, DrawerProps as MuiDrawerProps, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import './styles.css';

export interface DrawerProps extends MuiDrawerProps {
    open: boolean;
    title: string;
    className?: string;
    onClose?(): void;
}

export const Drawer: React.FC<DrawerProps> = ({ className, open, onClose, children, title }) => (
    <DefaultDrawer className={classnames('custom-drawer', className)} anchor="right" open={open} onClose={onClose}>
        <div className="drawer-header">
            <div className="title">{title}</div>
            {onClose && (
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            )}
        </div>
        {children}
    </DefaultDrawer>
);
