import { GridColTypeDef } from '@material-ui/x-grid';
import { notEquals, equals, contain, greater, less } from './custom-filter-operators';

export const GT_COLUMN_TYPES_RELATION = {
    string: 'string_GT',
    phone: 'phone_GT',
    boolean: 'boolean_GT',
    date: 'date_GT',
    integer: 'integer_GT',
    double: 'double_GT',
    currency: 'currency_GT',
    list: 'list_GT',
} as const;

/**
 * Кастомные типы данных для X-Grid, в случае если необходимо удалить или добавить
 * дополнительные типы данных, то необходимо мутировать объект.
 * @filterOperators позволяет править фильтрацию (операторы фильтров) по конкретному типу данных
 */
export const CUSTOM_COLUMN_TYPES: { [K: string]: GridColTypeDef } = {
    [GT_COLUMN_TYPES_RELATION.string]: {
        extendType: 'string',
        filterOperators: [notEquals, equals, contain],
    },
    [GT_COLUMN_TYPES_RELATION.integer]: {
        extendType: 'number',
        filterOperators: [equals, notEquals, contain, greater, less],
    },
    [GT_COLUMN_TYPES_RELATION.double]: {
        extendType: 'number',
        filterOperators: [equals, notEquals, contain, greater, less],
    },
    [GT_COLUMN_TYPES_RELATION.date]: {
        extendType: 'date',
        filterOperators: [equals, notEquals, contain, greater, less],
    },
    [GT_COLUMN_TYPES_RELATION.currency]: {
        extendType: 'number',
        filterOperators: [equals, notEquals, contain, greater, less],
    },
    [GT_COLUMN_TYPES_RELATION.phone]: {
        extendType: 'string',
        filterOperators: [notEquals, equals, contain],
    },
    [GT_COLUMN_TYPES_RELATION.boolean]: {
        extendType: 'boolean',
        filterOperators: [notEquals, equals],
    },
    [GT_COLUMN_TYPES_RELATION.list]: {
        extendType: 'string',
        filterOperators: [notEquals, equals, contain],
    },
};
