import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import {
    XGrid,
    GridColumns,
    GridRowsProp,
    ruRUGrid,
    GridPagination,
    LicenseInfo,
    GridSelectionModel,
    GridSortModelParams,
    useGridApiRef,
    GridSortItem,
    GridSortModel,
    GridColumnHeaderParams,
    XGridProps,
    GridColumnResizeParams,
    GridFilterModel,
    GridFilterItem,
    GridColDef,
} from '@material-ui/x-grid';
import {
    Checkbox,
    IconButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
} from '@material-ui/core';
import { AddCircleOutline, Edit, HighlightOff, Settings } from '@material-ui/icons';

import { getColumns, getSettingsColumn, defaultFiltersMapper } from './utils';
import { deepEqual } from '../../utils/deep-equal';
import { SettingColumnMenu } from './setting-column-menu';
import { COLUMN_CHECKBOX_NAME, COLUMN_SETTING_NAME } from './constants';
import { CUSTOM_COLUMN_TYPES } from './filters/custom-column-types';

import './x-grid-table.scss';

LicenseInfo.setLicenseKey(
    'e726e4a0056c2c09741c33b04e034e97T1JERVI6MjM3NzksRVhQSVJZPTE2NTAxOTYxNDIwMDAsS0VZVkVSU0lPTj0x'
);

export type TableParams = {
    size: number;
    page: number;
    sort: GridSortItem;
    filters: unknown[];
};

export type CustomColumn = GridColDef & {
    required?: boolean;
};
export type RowId = string | number;

export type CheckboxState = 'selectAll' | 'partiallySelected' | 'takeOutAll';

export type XGridTableProps = XGridProps & {
    columns: GridColumns;
    rows: GridRowsProp;
    rowsPerPageOptions: number[];
    /**
     * устанавливает количество строк в таблице
     */
    pageSize: number;
    /**
     * устанавливает начальную страницу
     */
    pageStart: number;
    /**
     * mode сортировки и пагинации
     */
    mode: 'client' | 'server';
    /**
     * устанавливает сортировку по умолчанию
     */
    initSortModel?: GridSortModel;
    onSelectionModelChange?: XGridProps['onSelectionModelChange'];
    filterMapper?: (filters: GridFilterItem[], columns: GridColumns) => unknown[];
    className?: string;
    /**
     * устанавливает выбранные строки
     */
    selectionModel?: RowId[];
    onParametersChange?: (params: TableParams) => void;
    onSelectAll?: () => void;
    onRowDoubleClick?: XGridProps['onRowDoubleClick'];
    onRowClick?: XGridProps['onRowClick'];
    /**
     * устанавливает количество строк
     */
    rowCount?: number;
    hideFooterSelectedRowCount?: boolean;
    withSettings?: boolean;
    renderCustomColumn?: (params: {
        columns: CustomColumn[];
        onVisibilityChange: (field: string) => void;
        onAddCustomColumn: XGridTableProps['onAddCustomColumn'];
        onEditCustomColumn: XGridTableProps['onEditCustomColumn'];
        onRemoveCustomColumn: XGridTableProps['onRemoveCustomColumn'];
    }) => React.ReactNode;
    /**
     * Уникальный ключ для сохранения настроек отображения колонок в local storage
     */
    localStorageCache?: string;
    /**
     * Отключает меню колонки, тем самым не отключает фильтрацию
     * @default true
     */
    disableFiltering?: boolean;
    checkboxSelection?: XGridProps['checkboxSelection'];
    onAddCustomColumn?: () => void;
    onEditCustomColumn?: (column: CustomColumn) => void;
    onRemoveCustomColumn?: (column: CustomColumn) => void;
};

export const XGridTable: React.FC<XGridTableProps> = ({
    columns: outputColumns,
    rows,
    rowsPerPageOptions,
    pageSize: pageSizeProp,
    pageStart,
    disableFiltering = true,
    onParametersChange,
    onSelectionModelChange,
    mode = 'client',
    initSortModel,
    onSelectAll,
    filterMapper = defaultFiltersMapper,
    rowCount,
    withSettings,
    renderCustomColumn,
    localStorageCache,
    hideFooterSelectedRowCount,
    selectionModel: selectionModelProps,
    className,
    onRowDoubleClick,
    onRowClick,
    checkboxSelection = true,
    onAddCustomColumn,
    onEditCustomColumn,
    onRemoveCustomColumn,
    ...rest
}) => {
    const gridApiRef = useGridApiRef();
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [page, setPage] = useState(pageStart);
    const [sortModel, setSortModel] = useState<GridSortModelParams['sortModel']>(initSortModel || []);
    const [filterModel, setFilterModel] = useState<unknown[]>([]);
    const [columns, setColumns] = useState<GridColumns>(getColumns(outputColumns, localStorageCache));
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const columnsForSettings: CustomColumn[] = useMemo(
        () => columns.filter((el): boolean => el.headerName?.trim() !== ''),
        [columns]
    );

    const refMenuAnchor = React.useRef<HTMLElement | null>(null);

    const saveColumnsToLocaleStorage = useCallback(
        (columns: GridColumns) => {
            const withoutCheckbox = columns.filter(
                (el) => ![COLUMN_CHECKBOX_NAME, COLUMN_SETTING_NAME].includes(el.field)
            );

            setColumns(withoutCheckbox);

            if (localStorageCache) {
                localStorage.setItem(localStorageCache, JSON.stringify(withoutCheckbox));
            }
        },
        [localStorageCache]
    );

    const handleSettingsItemClick: (field: string) => void = useCallback(
        (field: string) => {
            const allColumns = gridApiRef.current.getAllColumns();

            const newColumns = allColumns.map((el) => {
                if (el.field === field) {
                    return { ...el, hide: !el.hide };
                }
                return el;
            });

            saveColumnsToLocaleStorage(newColumns);
        },
        [saveColumnsToLocaleStorage]
    );

    const settingsComponentCalculated: React.ReactNode = useMemo(
        () => (
            <IconButton onClick={() => setIsOpenMenu(true)} aria-label="Settings">
                <Settings />
            </IconButton>
        ),
        [columnsForSettings]
    );

    const tableColumns = useMemo(
        () => (withSettings ? columns.concat(getSettingsColumn(settingsComponentCalculated)) : columns),
        [withSettings, settingsComponentCalculated, columns]
    );

    const handlePageSizeChange = (size: number): void => {
        setPageSize(size);

        if (onParametersChange) {
            onParametersChange({
                size,
                page,
                sort: sortModel[0],
                filters: filterModel,
            });
        }
    };

    const handlePageChange = (page: number): void => {
        setPage(page);

        if (onParametersChange) {
            onParametersChange({
                size: pageSize,
                page,
                sort: sortModel[0],
                filters: filterModel,
            });
        }
    };

    const handleSortModelChange = (newSortModel: GridSortModel): void => {
        if (!deepEqual(sortModel, newSortModel)) {
            setSortModel(newSortModel);

            onParametersChange?.({
                size: pageSize,
                page,
                sort: newSortModel?.[0] || sortModel[0],
                filters: filterModel,
            });
        }
    };

    const handleFilterModelChange = (filterModel: GridFilterModel): void => {
        const filters = filterMapper(filterModel?.items || filterModel, columns);

        setFilterModel(filters);

        if (onParametersChange) {
            onParametersChange({
                size: pageSize,
                page,
                sort: sortModel[0],
                filters,
            });
        }
    };

    const handleResizeColumn = useCallback(
        ({ colDef, width }: GridColumnResizeParams) => {
            const allColumns = gridApiRef.current.getAllColumns();

            const newColumns = allColumns.map((el) => {
                if (el.field === colDef.field) {
                    return { ...el, width: width };
                }
                return el;
            });

            saveColumnsToLocaleStorage(newColumns);
        },
        [gridApiRef, saveColumnsToLocaleStorage]
    );

    const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
        if (onSelectionModelChange) {
            onSelectionModelChange(selectionModel);
        }
    };

    useEffect(() => {
        if (onParametersChange) {
            onParametersChange({
                size: pageSize,
                page,
                sort: sortModel[0],
                filters: filterModel,
            });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setMenuAnchor(refMenuAnchor.current);
    }, [refMenuAnchor]);

    useEffect(() => {
        setColumns(getColumns(outputColumns, localStorageCache));
    }, [outputColumns, localStorageCache]);

    useEffect(() => {
        setPage(pageStart);
    }, [pageStart]);

    return (
        <Paper ref={refMenuAnchor} className={classnames('x-grid-table-wrapper', className)}>
            <XGrid
                {...rest}
                // required prop
                columns={tableColumns}
                rows={rows}
                localeText={{
                    ...ruRUGrid,
                    noRowsLabel: 'Нет записей',
                    footerRowSelected: (count: number) => (checkboxSelection ? `Выбрано: ${count}` : null),
                }}
                density="standard"
                headerHeight={56}
                rowHeight={35}
                columnBuffer={0}
                scrollEndThreshold={0}
                sortingOrder={['asc', 'desc']}
                // required prop end
                checkboxSelection={checkboxSelection}
                // custom props start
                apiRef={gridApiRef}
                rowsPerPageOptions={rowsPerPageOptions}
                pageSize={pageSize}
                page={page}
                onSelectionModelChange={handleSelectionModelChange}
                sortingMode={mode}
                paginationMode={mode}
                filterMode={mode}
                rowCount={rowCount}
                hideFooterSelectedRowCount={hideFooterSelectedRowCount}
                sortModel={sortModel}
                selectionModel={selectionModelProps}
                disableColumnMenu={disableFiltering}
                columnTypes={CUSTOM_COLUMN_TYPES}
                disableColumnSelector={true} // блокировка скрытия колонок в меню
                disableMultipleColumnsSorting={true}
                loading={false}
                pagination={true}
                disableSelectionOnClick={true}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                onSortModelChange={handleSortModelChange}
                onRowDoubleClick={onRowDoubleClick}
                onRowClick={onRowClick}
                onColumnResize={handleResizeColumn}
                onFilterModelChange={handleFilterModelChange}
                onColumnHeaderClick={(params: GridColumnHeaderParams) => {
                    if (params.field !== COLUMN_CHECKBOX_NAME) {
                        return;
                    }

                    if (!gridApiRef.current.getSelectedRows().size) {
                        onSelectAll?.();
                    }
                }}
                components={{
                    Pagination: () => (
                        <GridPagination
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            labelRowsPerPage="Строк на странице"
                            labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) =>
                                `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
                            }
                        />
                    ),
                }}
                hideFooterRowCount={true}
            />
            <SettingColumnMenu open={isOpenMenu} handleClose={() => setIsOpenMenu(false)} menuAnchor={menuAnchor}>
                {renderCustomColumn ? (
                    renderCustomColumn({
                        columns: columnsForSettings,
                        onVisibilityChange: handleSettingsItemClick,
                        onAddCustomColumn,
                        onEditCustomColumn,
                        onRemoveCustomColumn,
                    })
                ) : (
                    <div>
                        {!onAddCustomColumn && <MenuItem disabled={true}>Список колонок</MenuItem>}
                        {onAddCustomColumn && (
                            <MenuItem button={true}>
                                <ListItemText>Отображаемые колонки</ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton size="small" edge="end" onClick={onAddCustomColumn}>
                                        <AddCircleOutline />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </MenuItem>
                        )}
                        {columnsForSettings.map((el) => (
                            <MenuItem
                                key={`${el.field}-${el.headerName}`}
                                disabled={el?.required}
                                className="menu-item"
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={!el.hide}
                                        disabled={el?.required}
                                        onClick={() => handleSettingsItemClick(el.field)}
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText>{el.headerName}</ListItemText>
                                <ListItemSecondaryAction>
                                    {el.editable && onEditCustomColumn && (
                                        <IconButton size="small" edge="end" onClick={() => onEditCustomColumn(el)}>
                                            <Edit />
                                        </IconButton>
                                    )}
                                    {!el.required && onRemoveCustomColumn && (
                                        <IconButton size="small" edge="end" onClick={() => onRemoveCustomColumn(el)}>
                                            <HighlightOff />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </MenuItem>
                        ))}
                    </div>
                )}
            </SettingColumnMenu>
        </Paper>
    );
};
